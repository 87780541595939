<template>
    <div class=container>
        <tr>
    <td height="100%" align="left" valign="baseline" bgcolor="#FFFFFF">      <div align="center">        <table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"> <font size="+2">BRDF Parameter Database</font></div></td>
          </tr>
          <tr>
            <td valign="baseline"><p>The Oren-Nayar model parameters are given in the files </p>
              <ul>
                <li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/op.dat"> op.dat </a> - fits using radiance data averaged over R/G/B.
                </li><li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/opr.dat">opr.dat</a> - fits using radiance data from the red pixels,
                </li><li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/opg.dat">opg.dat</a> - fits using radiance data from the green pixels, and
                </li><li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/opb.dat">opb.dat</a> - fits using radiance data from the blue pixels.
              </li></ul>
              The format of each of these files is as follows:
              <pre>1 rho sigma kd
2 rho sigma kd
.
.
.
61 rho sigma kd
              </pre>
where the first number in each row is the sample number and the subsequent numbers are the estimated parameters of the Oren-Nayar model: rho, sigma and kd.
<p> <router-link to="/repository/CURET/spheres3">Spheres</router-link> are shown rendered with the estimated radiance values as predicted by the 3 parameter Oren-Nayar model. </p>
<hr>
The 5 parameters of the order 2 Koenderink et al. representation are given in the files
<ul>
  <li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/kp2.dat">kp2.dat</a> - fits using brdf data averaged over R/G/B.
  </li><li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/kp2r.dat">kp2r.dat</a> - fits using brdf data from the red pixels,
  </li><li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/kp2g.dat">kp2g.dat</a> - fits using brdf data from the green pixels, and
  </li><li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/kp2b.dat">kp2b.dat</a> - fits using brdf data from the blue pixels.
</li></ul>
The format of each of these files is as follows:
<pre>1 p1 p2 p3 p4 p5
2 p1 p2 p3 p4 p5
.
.
.
61 p1 p2 p3 p4 p5
</pre>
where the first number in each row is the sample number and the subsequent numbers are the 5 estimated parameters of the Koenderink et al. representation.
<p> <router-link to="/repository/CURET/spheres5">Spheres</router-link> are shown rendered with the estimated radiance values as predicted by the 5 parameter Koenderink et al. representation. </p>
<hr>
The 55 parameters of the order 8 Koenderink et al. representation are given in the files
<ul>
  <li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/kp8.dat">kp8.dat</a> - fits using brdf data averaged over R/G/B.
  </li><li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/kp8r.dat">kp8r.dat</a> - fits using brdf data from the red pixels,
  </li><li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/kp8g.dat">kp8g.dat</a> - fits using brdf data from the green pixels, and
  </li><li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/kp8b.dat">kp8b.dat</a> - fits using brdf data from the blue pixels.
</li></ul>
The format of each of these files is as follows:
<pre>1 p1 p2 ... p55
2 p1 p2 ... p55
.
.
.
61 p1 p2 ... p55
</pre>
where the first number in each row is the sample number and the subsequent numbers are the 55 estimated parameters of the order 8 Koenderink et al. representation.
<hr>
<hr>
For the anisotropic samples, the 55 parameter, order 3, anisotropic form of the Koenderink et al. representation is fit to the data and parameters are given in the files
<ul>
  <li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/kpan3.dat">kpan3.dat</a> - fits using brdf data averaged over R/G/B.
  </li><li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/kpan3r.dat">kpan3r.dat</a> - fits using brdf data from the red pixels,
  </li><li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/kpan3g.dat">kpan3g.dat</a> - fits using brdf data from the green pixels, and
  </li><li> <a href="https://cave.cs.columbia.edu/old/software/curet/data/kpan3b.dat">kpan3b.dat</a> - fits using brdf data from the blue pixels.
</li></ul>
The format of each of these files is as follows:
<pre>1 p1 p2 ... p55
2 p1 p2 ... p55
.
.
.
61 p1 p2 ... p55
</pre>
where the first number in each row is the sample number and the subsequent numbers are the 55 estimated parameters of the order 3 anisotropic Koenderink et al. representation.
<p> <router-link to="/repository/CURET/spheres55">Spheres</router-link> are shown rendered with the estimated radiance values as predicted by the 55 parameter Koenderink et al. representation. </p></td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center">              <em>
              <div align="left">                </div>
            </em>
                <div align="left"></div>
                <div align="left"><hr align="left" size="4">
                  <p><router-link to="/repository/CURET"> [Return to Database Home Page] </router-link></p>
                  </div>
            </div></td>
          </tr>
        </tbody></table>
        <br>    
        <br>
        <br>
        <br>
        <br>
        </div>
    </td>
  </tr>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>